import styled from "styled-components"

export const PortfolioCardContainer = styled.div`
    display: inline-block;
    margin: 20px;

    @media (max-width: 800px) {
        margin: 5px;
    }
`

export const PortfolioImage = styled.img`
    display: inline-block;
    border-radius: ${props => props.theme.borderRadius};
    width: 45vw;

    @media (max-width: 800px) {
        width: 90vw;
        border-radius: 7px;
    }
`