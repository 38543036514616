import styled from "styled-components"

export const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 3rem;
`

export const LogoImg = styled.img`
    width: 100%;
    max-width: 500px;
    height: auto;
`

export const Moto = styled.span`
    display: block;
    text-align: center;
    margin-top: 1rem;
    font-size: 2.5rem;
    font-family: ${props => props.theme.textThinFont};

    @media (min-width: 768px) {
        font-size: 3.5rem;
    }
`