import React from "react"
import { IntroContainer, Description, Contact } from "./IntroStyle"
import Logo from "./Logo"


const Intro = (props) => (
    <IntroContainer>
        <Logo />
        <Description>{props.invoodoo.description}</Description>
        <Contact href={`mailto:${props.invoodoo.email}`} fontSize="3rem">{props.invoodoo.email}</Contact>
    </IntroContainer>
)

export default Intro