import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { PortfolioContainer } from "./PortfolioStyle"
import PortfolioCard from "./PortfolioCard";

const Portfolio = () => (
    <StaticQuery query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "portfolio" }}},
          sort: { fields: [frontmatter___position], order: ASC}) {
          edges {
            node {
              frontmatter {
                id
                title
                description
                image
              }
            }
          }
        }
      }
    `}
    render = { data => (
      <PortfolioContainer>
        {
          data.allMarkdownRemark.edges.map(portfolio => 
            <PortfolioCard key={portfolio.node.frontmatter.id} portfolio={portfolio.node.frontmatter} />
          )
        }
      </PortfolioContainer>
    )}
  />
)

export default Portfolio