import React from "react";
import SEO from "../SEO/SEO";
import { StaticQuery, graphql } from "gatsby";

import Intro from "./intro";
import Portfolio from "./Portfolio/Portfolio";

const Services = () => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { id: { eq: "invoodoo" } }) {
          frontmatter {
            title
            description
            keywords
            path
            imgPath
            email
          }
        }
      }
    `}
    render={data => (
      <>
        <SEO
          title={data.markdownRemark.frontmatter.title}
          description={
            data.markdownRemark.frontmatter.description
          }
          keywords={data.markdownRemark.frontmatter.keywords}
          path={data.markdownRemark.frontmatter.path}
          imgPath={data.markdownRemark.frontmatter.imgPath}
        />
        <Intro invoodoo={data.markdownRemark.frontmatter} />
        <Portfolio />
      </>
    )}
  />
);

export default Services;
