import styled from "styled-components"

export const IntroContainer = styled.div`
    width: 100%;
    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-height: 500px) {
        height: 500px;
    }
`

export const Description = styled.span`
    display: block;
    font-size: 2.5rem;
    text-align: center;
    width: 70%;
    margin-top: 5%;
    margin-bottom: 10%;
    font-family: ${props => props.theme.textThinFont};

    @media (max-width: 600px), (max-height: 500px) {
        font-size: 3vw;
        margin-top: 10%;
        margin-bottom: 15%;
    }
`

export const Contact = styled.a`
    font-size: ${props => props.fontSize};
    color: ${props => props.theme.linkColor};
    font-family: ${props => props.theme.textThinFont};

    &:link {
        color: ${props => props.theme.linkColor};
    }

    &:hover {
        color: ${props => props.theme.linkHoveredColor};
        text-decoration: none;
    }

    @media (max-width: 600px), (max-height: 500px) {
        font-size: 3vw;
    }
`