import React from "react"
import GlobalStyles from "../styles/global-style"

import Services from "../components/Services/Services"
import Layout from "../layout/Layout"

const App = () => (
  <>
    <GlobalStyles />

    <Layout>
        <Services />
    </Layout>
  </>
)

export default App