import React from "react"
import { LogoContainer, LogoImg, Moto } from "./LogoStyle"

const LogoAndMoto = () => (
    <LogoContainer>
        <LogoImg src="/images/invoodoo-logo.svg"/>
        <Moto>Making Things Work</Moto>
    </LogoContainer>
)

export default LogoAndMoto